.grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
  padding: 10px;
}
.grid-item {
  padding: 20px;
  text-align: center;
}

.ant-carousel .slick-dots li button {
  background: #000 !important;
  opacity: 0.4;
}

.ant-carousel .slick-dots li.slick-active button {
  opacity: 1;
  background: #000 !important;
}

.site-card-border-less-wrapper {
  padding: 30px;
  background: #ececec;
}