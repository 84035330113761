.orgs-v2-container {
  padding: 16px;
  background-color: #f0f2f5;
  min-height: calc(100vh - 64px);
}

.orgs-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 16px;
  width: 100%;
}

@media (min-width: 768px) {
  .orgs-header {
    flex-direction: row;
    align-items: center;
  }
}

.orgs-controls {
  margin-top: 16px;
  width: 100%;
}

@media (min-width: 768px) {
  .orgs-controls {
    margin-top: 0;
    justify-content: flex-end;
    max-width: 70%;
  }
}

/* Add styling for filter tags */
.ant-tag {
  margin-bottom: 8px;
  font-size: 14px;
  padding: 4px 8px;
}

/* Styling for filter buttons */
.orgs-controls .ant-btn {
  margin-right: 8px;
  margin-bottom: 8px;
}

/* Styling for search field */
.orgs-controls .ant-input-search {
  margin-left: 8px;
  margin-right: 8px;
}

/* Styling for the filter popover form */
.ant-popover-inner-content .ant-form-item:last-child {
  margin-bottom: 0;
}

/* Mobile responsive styles */
@media (max-width: 768px) {
  .orgs-header {
    flex-direction: column;
    align-items: stretch;
  }

  .orgs-controls {
    width: 100%;
    max-width: 100%;
  }

  .ant-table-content {
    overflow-x: auto;
  }
}

@media (max-width: 480px) {
  .ant-input-search {
    width: 100% !important;
    max-width: 100% !important;
  }

  .orgs-controls {
    flex-direction: column;
    align-items: stretch;
  }

  .ant-table-pagination {
    flex-wrap: wrap;
  }
}

.filters-container {
  margin: 8px 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: #f5f5f5;
  padding: 8px;
  border-radius: 4px;
}

/* Style for filter tags with selectors */
.filters-container .ant-tag {
  padding: 4px 8px;
  margin-bottom: 4px;
  margin-right: 8px;
}

.filters-container .ant-tag span {
  vertical-align: middle;
} 