.apps {
  display: flex;
  flex-wrap: wrap;
  color: #444;
  align-items: center;
  margin-bottom: 30px;
}
.container {
  width: 30%;
  max-width: 1200px;
  margin: 0 auto;
}

.app {
  position: relative;
  background-color: #fff;
  color: #f9fafa;
  padding: 16px;
  height: 140px;
  width: 100px;
  text-align: center;
  text-decoration: none;
  -webkit-transition: box-shadow 0.1s;
  transition: box-shadow 0.1s;
  margin-right: 16px;
  box-shadow: none;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  outline: 0;
  margin-bottom: 20px;
  box-shadow: 0 0px 2px #000000b0;
}

.app:hover {
  box-shadow: rgba(198, 62, 58, 1) 0 0 0px 7px;
}

.app__cover {
  margin: 0 auto;
  max-height: 50px;
  max-width: 100%;  
}

.app__cover img {
  width: 100%;
  height: auto;
}

.app__label {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 60px;
  background: #278A74;
  text-align: center;
  padding: 2px;
  box-sizing: border-box;
  font-size: 15px;
}

.footer {
  width: 100%;
  color: #aaa;
  background: #0c161c;
  padding: 50px;
  font-size: 18px;
  box-sizing: border-box;
}

.footer a {
  color: white;
}

@media (min-width: 810px) {
  .header h1 {
    font-size: 30px;
  }

  .wrapper {
    display: flex;
  }

  h2 {
    margin: 20px 0 20px 0;
    font-size: 30px;
  }

  .app__cover {
    height: auto;
  }
}

@media (max-width: 560px) {
  .header h1 {
    display: none;
  }
}

@media (max-width: 810px) {
  .container {
    padding: 0 30px 0px 30px;
  }
}

@media (max-width: 480px) {
  .container {
    width: 100%;
    padding: 0 10px;
  }
  .apps {
    justify-content: center;
  }
  .app {
    width: 80px;
    height: 140px;
    margin-right: 8px;
    margin-bottom: 15px;
  }
  .app__label {
    font-size: 13px;
    padding: 3px 0;
  }
  h2 {
    font-size: 22px;
    margin: 10px 0;
  }
}