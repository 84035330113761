.org-details-v2-container {
  padding: 20px;
}

.org-details-layout {
  background: #fff;
  min-height: calc(100vh - 200px);
}

.org-details-sider {
  background: #fff;
  border-right: 1px solid #f0f0f0;
}

.org-details-content {
  padding: 0 24px 24px;
  background: #fff;
}

.org-header {
  display: flex;
  flex-direction: column;
}

.mobile-tab-selector {
  width: 100%;
}

.mobile-menu-button {
  margin-bottom: 16px;
}

@media (max-width: 768px) {
  .org-details-layout {
    flex-direction: column;
  }
  
  .org-details-content {
    padding: 16px;
  }
  
  .org-details-v2-container {
    padding: 12px;
  }
}

@media (min-width: 769px) {
  .mobile-tab-selector,
  .mobile-menu-button {
    display: none;
  }
}

/* Grid container for Removebg and similar components */
.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 16px;
  margin-top: 16px;
}

.grid-item {
  margin-bottom: 16px;
}

@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: 1fr;
  }
} 