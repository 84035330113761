.ProjectBuild {
  height: auto;
  grid-template-columns: 250px;
  grid-row: auto auto;
  margin: 10px;
  min-height: 200px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: 1px 1px 2px #000;
  border-radius: 5px;
}

.ProjectBuild a {
    text-decoration: none;
}

.ProjectBuild-detailsTable {
    text-align: left;
    margin: 0 20px;
    color: #fff;
}

.ProjectBuild.failed {
    background-color: #C63E3A;
}

.ProjectBuild.succeeded {
    background-color: #278A74;
}

.ProjectBuild.in_progress {
    background-color: #E3B23C;
}

.ProjectBuild.stopped {
    background-color: grey;
}

.ProjectBuild h1 {
    font-size: 1.5em;
    margin: 20px 20px 0 20px;
    flex: 1;
    color: #fff;
}

.ProjectBuild span {
    flex: 1;
}

.ProjectBuild-hash {
    font-size: 1rem;
}

.ProjectBuild-time span.MuiBadge-badge {
    margin: -3px;
}

.ProjectBuild-link {
    margin: 5px 0 0 20px;
    display: flex;
    align-items: center;
    text-align: center;
}
